<template>
  <router-view />
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
export default {
  name: 'Workspaces',
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId', 'needRedirect']),
    ...mapGetters('Workspace', ['getWorkspaceById']),
  },
  watch: {
    $route() {
      this.onWorkspaceLoad();
    },
  },
  created() {
    this.onWorkspaceLoad();
  },
  methods: {
    ...mapMutations('Workspace', ['setIsNeedRedirect']),
    ...mapActions('Projects', ['getProjectsCount']),
    async onWorkspaceLoad() {
      if (this.needRedirect) {
        this.setIsNeedRedirect(false);
        const response = await this.getProjectsCount();
        if (response.length) {
          this.$router.replace({
            name: 'projects',
            params: {
              wId: this.activeWorkspaceId,
            },
          }).catch(() => {
          });
        } else {
          this.$router.push({
            name: 'community-collections',
            params: {
              wId: this.activeWorkspaceId,
              id: 'community',
            },
          }).catch(() => {
          });
        }
      }
    },
  },
};
</script>
<style scoped>
</style>
